import { Theme } from '@mui/material';

import { ContentfulBackground } from '@/types';
import { resolveContentfulBgColor } from '@/utils';

export const imageSelectOptionsCtrStyles = {
	display: 'flex',
	justifyContent: 'space-between',
	height: '6.25rem',
	width: '18.75rem',
	backgroundColor: 'neutrals.white',
	border: '0',
	borderRadius: '.5rem',
};

export const imageSelectOptionsItmStyles = (isActive: boolean) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	alignContent: 'center',
	height: '6.1rem',
	width: '6.25rem',
	...(isActive && { borderBottom: '.125rem solid', borderColor: 'text.interactive', borderRadius: 'inherit' }),
});

export const cameraClickIconStyles = (theme: Theme) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	alignContent: 'center',
	height: '6.1rem',
	width: '6.25rem',
	background: resolveContentfulBgColor('Dark' as ContentfulBackground, theme),
});
