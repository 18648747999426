import { Box, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import { IDentrinoTryOnStateObject } from '../DentrinoVirtualTryOn.helpers';
import ImageSelectOptions from '../ImageSelectOptions/ImageSelectOptions';
import ImageAndQRCodeScanTips from '../ImageAndQRCodeScanTips/ImageAndQRCodeScanTips';
import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
	imageSelectOptionsStyles,
} from '../DentrinoVirtualTryOn.styles';

import { uploadContentBoxStyles, uploadContentDropGridItemStyle } from './ImageFromUpload.styles';

export interface IImageFromUpload {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function ImageFromUpload({ state, setState }: IImageFromUpload) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleDrop = (event: React.DragEvent) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles.length > 0) {
			const file = Array.from(droppedFiles)[0];
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				function () {
					setState({
						selectedFileUrl: reader.result as string,
						formType: 'ImageCrop',
						previousStep: 'ImageFromUpload',
					});
				},
				false
			);
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const selectedFiles = event.target.files;
		if (selectedFiles && selectedFiles.length > 0) {
			const file = Array.from(selectedFiles)[0];
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				function () {
					setState({
						selectedFileUrl: reader.result as string,
						formType: 'ImageCrop',
						previousStep: 'ImageFromUpload',
					});
				},
				false
			);
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<DialogContent>
			<Grid container sx={dialogContentContainerStyles(isSmallScreen)}>
				<Grid item container sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<Grid item sx={uploadContentDropGridItemStyle}>
						<Box
							sx={uploadContentBoxStyles}
							onDrop={handleDrop}
							onDragOver={(event) => event.preventDefault()}
						>
							<input
								type="file"
								hidden
								id="browse"
								onChange={handleFileChange}
								accept=".png,.jpeg,.jpg"
							/>
							<TAGSvgIcon
								icon={'FileUploadOutlined'}
								style={{ color: `${theme.palette.text.disabled}` }}
							/>
							<Typography variant={'bodyMediumSemiBold'} color={'text.tertiary'}>
								Drag & drop files or
								<Typography variant={'bodyMediumBold'} color={'text.interactive'}>
									<input
										type="file"
										hidden
										id="browse"
										onChange={handleFileChange}
										accept=".png,.jpeg,.jpg"
									/>
									<label htmlFor="browse" style={{ cursor: 'pointer' }}>
										&nbsp;Choose file
									</label>
								</Typography>
								&nbsp;to upload
							</Typography>
							<Typography variant={'bodyMediumSemiBold'} color={'text.tertiary'}>
								PNG or JPG
							</Typography>
						</Box>
					</Grid>
					<Grid item sx={imageSelectOptionsStyles}>
						<ImageSelectOptions state={state} setState={setState} />
					</Grid>
				</Grid>
				<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<ImageAndQRCodeScanTips formType={state.formType} />
				</Grid>
			</Grid>
		</DialogContent>
	);
}
