import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGSvgIcon from '@/components/TAGSvgIcon';

import { IDentrinoTryOnStateObject } from '../DentrinoVirtualTryOn.helpers';

import {
	cameraClickIconStyles,
	imageSelectOptionsCtrStyles,
	imageSelectOptionsItmStyles,
} from './ImageSelectOptions.styles';

export interface IImageSelectOptions {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
	handleCameraClick?: () => void;
}

export default function ImageSelectOptions({ state, setState, handleCameraClick }: IImageSelectOptions) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { formType, cameraAccessError } = state;

	const goToUpload = () => {
		setState({
			formType: 'ImageFromUpload',
		});
	};

	const goToCamera = () => {
		setState({
			formType: 'ImageFromCamera',
		});
	};

	const goToScan = () => {
		setState({
			formType: 'QRCodeScan',
		});
	};

	if (isSmallScreen) {
		return null;
	}

	return (
		<>
			<Grid container sx={imageSelectOptionsCtrStyles}>
				<Grid item onClick={goToCamera}>
					<Box sx={imageSelectOptionsItmStyles(formType === 'ImageFromCamera' && !cameraAccessError)}>
						{!cameraAccessError && (
							<TAGSvgIcon
								icon={'CameraAlt'}
								style={{
									color:
										formType === 'ImageFromCamera'
											? `${theme.palette.text.interactive}`
											: `${theme.palette.text.secondary}`,
								}}
							/>
						)}
						{cameraAccessError && (
							<TAGSvgIcon
								icon={'NoPhotography'}
								style={{
									color: `${theme.palette.text.secondary}`,
								}}
							/>
						)}
						<Typography
							variant={'tabLabelInactive'}
							color={
								formType === 'ImageFromCamera' && !cameraAccessError
									? 'text.interactive'
									: 'text.secondary'
							}
						>
							Camera
						</Typography>
					</Box>
				</Grid>
				<Grid item onClick={goToUpload}>
					<Box sx={imageSelectOptionsItmStyles(formType === 'ImageFromUpload')}>
						<TAGSvgIcon
							icon={'InsertPhoto'}
							style={{
								color:
									formType === 'ImageFromUpload'
										? `${theme.palette.text.interactive}`
										: `${theme.palette.text.secondary}`,
							}}
						/>
						<Typography
							variant={'tabLabelInactive'}
							color={formType === 'ImageFromUpload' ? 'text.interactive' : 'text.secondary'}
						>
							Upload
						</Typography>
					</Box>
				</Grid>
				<Grid item onClick={goToScan}>
					<Box sx={imageSelectOptionsItmStyles(formType === 'QRCodeScan')}>
						<TAGSvgIcon
							icon={'QrCode2'}
							style={{
								color:
									formType === 'QRCodeScan'
										? `${theme.palette.text.interactive}`
										: `${theme.palette.text.secondary}`,
							}}
						/>
						<Typography
							variant={'tabLabelInactive'}
							color={formType === 'QRCodeScan' ? 'text.interactive' : 'text.secondary'}
						>
							Scan
						</Typography>
					</Box>
				</Grid>
			</Grid>
			{formType === 'ImageFromCamera' && !cameraAccessError && (
				<Grid container sx={cameraClickIconStyles(theme)} onClick={handleCameraClick}>
					<TAGSvgIcon
						icon={'CircleTwoTone'}
						size={60}
						viewBox="0 0 60 60"
						style={{ color: `${theme.palette.text.light}` }}
					/>
				</Grid>
			)}
		</>
	);
}
