import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGButton from '@/components/TAGButton';

import { IDentrinoTryOnStateObject } from '../DentrinoVirtualTryOn.helpers';
import { errorContentCtrStyles, errorCtrStyles } from '../DentrinoVirtualTryOn.styles';

export interface IErrorPage {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function ErrorPage({ state, setState }: IErrorPage) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { errorMessage, cameraAccessError } = state;

	const handleTryAgain = () => {
		setState({
			formType: 'Instructions',
		});
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const selectedFiles = event.target.files;
		if (selectedFiles && selectedFiles.length > 0) {
			const file = Array.from(selectedFiles)[0];
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				function () {
					setState({
						selectedFileUrl: reader.result as string,
						formType: 'ImageCrop',
						previousStep: 'ImageFromCamera',
					});
				},
				false
			);
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<>
			<Grid container sx={errorCtrStyles(theme, isSmallScreen)}>
				<Grid container sx={errorContentCtrStyles(isSmallScreen, cameraAccessError)}>
					<Grid item>
						<Typography variant={'header2'} color={'text.light'}>
							Uh Oh
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'} color={'text.light'}>
							{errorMessage}
						</Typography>
					</Grid>
					{!cameraAccessError && (
						<Grid item marginTop={'.75rem'}>
							<TAGButton size={isSmallScreen ? 'S' : 'M'} variant="secondaryHC" onClick={handleTryAgain}>
								Try again
							</TAGButton>
						</Grid>
					)}
					{cameraAccessError && (
						<Grid
							item
							container
							marginTop={'2rem'}
							sx={errorContentCtrStyles(isSmallScreen, cameraAccessError)}
						>
							<Grid item>
								<Typography
									variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
									color={'text.light'}
								>
									Unable to use the camera?
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
									color={'text.light'}
								>
									<Typography
										variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'}
										color={'text.light'}
									>
										<input
											type="file"
											hidden
											id="browse"
											onChange={handleFileChange}
											accept=".png,.jpeg,.jpg"
										/>
										<label
											htmlFor="browse"
											style={{ cursor: 'pointer', textDecoration: 'underline' }}
										>
											Upload an image
										</label>
									</Typography>
									&nbsp;instead
								</Typography>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
}
