import { Theme } from '@mui/material';
import { resolveTypographyVariantStyles } from '@aspendental/themes';

import { ContentfulBackground } from '@/types';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';

export const getDialogWrapperStyles = (theme: Theme, isSmallScreen: boolean, formType: string) => ({
	'&.MuiModal-root': {
		...(isSmallScreen && { zIndex: 10001 }),
	},
	'.MuiPaper-root': {
		form: {
			display: 'contents',
		},
		...((formType === 'Instructions' || formType === 'Error') && {
			background: resolveContentfulBgColor('Dark' as ContentfulBackground, theme),
		}),
		height: 'max-content',
		maxWidth: '56.25rem',
		...(isSmallScreen && { margin: '0', height: '100%', maxHeight: '100%', width: '100%', borderRadius: '0' }),
	},
	'.MuiDialogContent-root': {
		display: 'flex',
		justifyContent: 'center',
		...(isSmallScreen && { margin: '0', alignItems: 'center' }),
		...(isSmallScreen && formType !== 'Instructions' && { padding: '0' }),
		...(!isSmallScreen && formType === 'Instructions' && { paddingBottom: '0' }),
		...(!isSmallScreen && formType !== 'Instructions' && { padding: '0' }),
	},
	'.MuiDialogActions-root': {
		paddingY: '3rem',
		...(isSmallScreen && { paddingTop: '0' }),
	},
});

export const dialogContentContainerStyles = (isSmallScreen: boolean) => {
	return {
		display: 'flex',
		flexDirection: isSmallScreen ? 'column' : 'row',
		flexWrap: 'nowrap',
		width: '100%',
		height: '100%',
		position: 'relative',
	};
};

export const dialogLeftContentCtrStyles = (theme: Theme, formType: string, isSmallScreen: boolean) => ({
	display: 'flex',
	width: isSmallScreen ? '100%' : '60%',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	background: resolveContentfulBgColor('Dark' as ContentfulBackground, theme),
	position: 'relative',
	...(isSmallScreen && (formType === 'ImageFromCamera' || formType === 'ImageCrop') && { height: '100%' }),
	...(formType === 'Results' && isSmallScreen && { paddingTop: '5.75rem', maxHeight: '59%' }),
});

export const dialogRightContentCtrStyles = (theme: Theme, formType: string, isSmallScreen: boolean) => ({
	display: 'flex',
	width: isSmallScreen ? '100%' : '40%',
	...(isSmallScreen && { flex: '1' }),
	flexDirection: 'column',
	justifyContent: isSmallScreen ? 'center' : 'flex-start',
	alignItems: 'center',
});

export const getCloseIconStyles = (formType: string, isSmallScreen: boolean) => ({
	position: 'absolute',
	top: '0.75rem',
	right: '0.3rem',
	zIndex: 5,
	color: 'text.secondary',
	...((formType === 'Instructions' || formType === 'Error' || isSmallScreen) && { color: 'text.light' }),
});

export const instructionsCtrStyles = (isSmallScreen: boolean) => {
	return {
		flexDirection: 'row',
		gap: '1rem',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		textColor: 'text.light',
		...(isSmallScreen && { height: 'max-content' }),
	};
};

export const instructionTitleStyles = {
	width: '100%',
	justifyContent: 'center',
};

export const instructionsImageAndStepsCtrStyles = (isSmallScreen: boolean) => {
	return {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		...(!isSmallScreen && { maxWidth: '75%' }),
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1rem',
		maxWidth: '26.25rem',
	};
};

export const helperTipsImageWrapperStyles = {
	height: '100%',
	width: '100%',
	borderRadius: '.5rem',
	maxWidth: '26.25rem',
	maxHeight: '15.25rem',
};

export const imageAndQRCodeScanTipsAndUserFormContainerStyles = (
	isSmallScreen: boolean,
	theme: Theme,
	formType: string,
	displayAsToolTipInMobile: boolean
) => {
	return {
		gap: '.75rem',
		padding: '3.75rem 1.75rem',
		...(isSmallScreen && {
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'flex-start',
			height: '100%',
			padding: '1.25rem 1.5rem',
			...(formType === 'Results' && {
				justifyContent: 'flex-start',
				alignItems: 'center',
				paddingTop: '2.25rem',
				...(isSmallScreen && { background: resolveContentfulBgColor('Dark' as ContentfulBackground, theme) }),
			}),
			...(displayAsToolTipInMobile && {
				position: 'fixed',
				maxHeight: '30rem',
				width: '90%',
				height: 'max-content',
				bottom: '9rem',
				right: '1.5rem',
				background: 'rgba(0, 0, 0, 0.70)',
				borderRadius: '.75rem',
				zIndex: 4,
			}),
		}),
		...(formType === 'QRCodeScan' && {
			minHeight: '34rem',
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			alignContent: 'flex-start',
		}),
	};
};

export const imageSelectOptionsStyles = {
	display: 'flex',
	position: 'absolute',
	bottom: 0,
	left: 0,
	width: '100%',
	justifyContent: 'center',
	paddingBottom: '1.25rem',
	zIndex: 1,
};

export const qrCodeCtrStyles = {
	display: 'flex',
	padding: '1.5rem',
	flex: '1',
	marginTop: '10%',
};

export const listStyles = {
	listStyle: 'decimal',
	pl: '1rem',
};

export const listItemStyles = (theme: Theme, titleVariant: string, displayAsToolTipInMobile: boolean) => ({
	display: 'list-item',
	'&::marker': {
		...resolveTypographyVariantStyles(theme, titleVariant),
		...(displayAsToolTipInMobile && { color: 'text.light' }),
	},
});

export const errorCtrStyles = (theme: Theme, isSmallScreen: boolean) => {
	return {
		flexDirection: 'row',
		gap: '1rem',
		alignItems: 'center',
		justifyContent: 'center',
		height: isSmallScreen ? '100%' : '33rem',
		background: resolveContentfulBgColor('Dark' as ContentfulBackground, theme),
	};
};

export const errorContentCtrStyles = (isSmallScreen: boolean, cameraAccessError: boolean) => {
	return {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		textColor: 'text.light',
		width: isSmallScreen ? '90%' : '50%',
		...(!isSmallScreen && cameraAccessError && { width: '80%' }),
	};
};
