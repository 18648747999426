import { useEffect, useState } from 'react';

import { DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Cropper from 'react-easy-crop';
import { type Area } from 'react-easy-crop';

import TAGButton from '@/components/TAGButton';
import { logError } from '@/utils/miscUtils';

import { CroppedImage, IDentrinoTryOnStateObject, imageCropHelpText } from '../DentrinoVirtualTryOn.helpers';
import ImageAndQRCodeScanTips from '../ImageAndQRCodeScanTips/ImageAndQRCodeScanTips';
import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
} from '../DentrinoVirtualTryOn.styles';

import { getCroppedImg } from './ImageCrop.helpers';
import { cropperContainerStyles, imageCropTextAndBtnsCtrStyles, imageCropTextGridItemStyles } from './ImageCrop.styles';

export interface IImageCrop {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function ImageCrop({ state, setState }: IImageCrop) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
	const [image, setImage] = useState<string>();

	const { selectedFileUrl } = state;

	useEffect(() => {
		setImage(selectedFileUrl);
	}, [selectedFileUrl]);

	if (!image) {
		return null;
	}

	const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const handleChange = () => {
		setState({
			formType: state.previousStep ? state.previousStep : 'Instructions',
			selectedFileUrl: '',
		});
	};

	const handleContinue = async () => {
		try {
			if (!image || !croppedAreaPixels) {
				return;
			}

			const croppedImage = (await getCroppedImg(image, croppedAreaPixels)) as CroppedImage;

			setState({
				croppedImage: croppedImage,
				formType: 'UserInfo',
			});
		} catch (err) {
			if (err instanceof Error) {
				logError(`DENTRINO_ERROR_CROP_IMAGE: ${err.message}`);
			}
		}
	};

	return (
		<>
			<DialogContent>
				<Grid container sx={dialogContentContainerStyles(isSmallScreen)} data-test-id="camera_1">
					<Grid
						item
						container
						sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}
						data-test-id="camera_2"
					>
						{image ? (
							<Cropper
								image={image}
								crop={crop}
								zoom={zoom}
								aspect={1}
								onCropChange={setCrop}
								onZoomChange={setZoom}
								onCropComplete={onCropComplete}
								style={{ containerStyle: cropperContainerStyles(isSmallScreen) }}
							/>
						) : null}
						<Grid item sx={imageCropTextGridItemStyles}>
							<Typography variant={'bodyMediumBook'} color={'text.light'}>
								{imageCropHelpText}
							</Typography>
						</Grid>
						<Grid item container sx={imageCropTextAndBtnsCtrStyles}>
							<Grid item container spacing={2} justifyContent={'center'} alignSelf={'flex-end'}>
								<Grid item>
									<TAGButton
										size={isSmallScreen ? 'S' : 'M'}
										variant="secondaryHC"
										onClick={handleChange}
									>
										Change image
									</TAGButton>
								</Grid>
								<Grid item>
									<TAGButton
										size={isSmallScreen ? 'S' : 'M'}
										variant="primaryHC"
										onClick={() => void handleContinue()}
									>
										Continue
									</TAGButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!isSmallScreen && (
						<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
							<ImageAndQRCodeScanTips formType={state.formType} />
						</Grid>
					)}
				</Grid>
			</DialogContent>
		</>
	);
}
