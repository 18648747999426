export const imageCropCtrGridCropperItmStyles = {
	position: 'relative',
	width: '100%',
	height: '100%',
};

export const cropperContainerStyles = (isSmallScreen: boolean) => ({
	width: ' 100%',
	height: '100%',
	maxHeight: '18.5rem',
	maxWidth: isSmallScreen ? '100%' : '18.5rem',
	justifySelf: 'center',
	top: '3.5rem',
	left: 'unset',
	right: 'unset',
});

export const imageCropTextAndBtnsCtrStyles = {
	position: 'absolute',
	bottom: '0',
	marginBottom: { xs: '1.25rem', md: '2.5rem' },
	alignItems: 'center',
	justifyContent: 'center',
	height: {
		xs: '25%',
		sm: '35%',
		lg: '25%',
	},
};

export const imageCropTextGridItemStyles = {
	display: 'flex',
	justifySelf: 'center',
	position: 'absolute',
	top: '23rem',
	alignSelf: 'center',
	width: '14rem',
	textAlign: 'center',
};
