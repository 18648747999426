import { useState } from 'react';

import { Dialog, IconButton, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAppContext } from '@/context';

import { getCloseIconStyles, getDialogWrapperStyles } from './DentrinoVirtualTryOn.styles';
import { IDentrinoTryOnStateObject, dentrinoGA4DataLayerPush } from './DentrinoVirtualTryOn.helpers';
import Instructions from './Instructions/Instructions';
import ImageFromCamera from './ImageFromCamera/ImageFromCamera';
import ImageFromUpload from './ImageFromUpload/ImageFromUpload';
import QRCodeScan from './QRCodeScan/QRCodeScan';
import ImageCrop from './ImageCrop/ImageCrop';
import UserInfo from './UserInfo/UserInfo';
import Results from './Results/Results';
import ErrorMessage from './ErrorMessage/ErrorMessage';

interface IDentrinoVirtualTryOn {
	/**
	 * If <b>true</b>, the component is shown.
	 * @type {boolean}
	 *
	 */
	openVirtualTry: boolean;
	/**
	 * Callback to fire when the component needs to be closed.
	 */
	handleCloseVirtualTry: () => void;
}

export default function DentrinoVirtualTryOn({ openVirtualTry, handleCloseVirtualTry }: IDentrinoVirtualTryOn) {
	const [state, setState] = useState<IDentrinoTryOnStateObject>({
		formType: 'Instructions',
		selectedFileUrl: '',
		croppedImage: { blob: null, url: '', height: 0, width: 0 },
		resultsFromDentrino: null,
		showHelperTipsInMobile: false,
		isLoading: false,
		previousStep: '',
		errorMessage: '',
		cameraAccessError: false,
	});

	const theme = useTheme();
	const { config } = useAppContext();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const { formType } = state;

	const updateState = (newState: Partial<IDentrinoTryOnStateObject>) => {
		setState({
			...state,
			...newState,
		});
	};

	const handleClose = () => {
		if (formType !== 'Results') {
			dentrinoGA4DataLayerPush(config.name, 'cancel');
		}
		updateState({
			formType: 'Instructions',
		});
		handleCloseVirtualTry();
	};

	const ContentWrapper = () => {
		return (
			<>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={getCloseIconStyles(state.formType, isSmallScreen)}
				>
					<CloseIcon />
				</IconButton>
				{/* Render Instructions Page initially  */}
				{formType === 'Instructions' && <Instructions state={state} setState={updateState} />}
				{/* Render Image from Camera Option  */}
				{formType === 'ImageFromCamera' && <ImageFromCamera state={state} setState={updateState} />}
				{/* Render Image from Upload Option  */}
				{formType === 'ImageFromUpload' && <ImageFromUpload state={state} setState={updateState} />}
				{/* Render Image Crop  */}
				{formType === 'ImageCrop' && <ImageCrop state={state} setState={updateState} />}
				{/* Render QR Code Option  */}
				{formType === 'QRCodeScan' && <QRCodeScan state={state} setState={updateState} />}
				{/* Render User Info */}
				{formType === 'UserInfo' && <UserInfo state={state} setState={updateState} />}
				{/* Render Results */}
				{formType === 'Results' && <Results state={state} />}
				{/* Render Error */}
				{formType === 'Error' && <ErrorMessage state={state} setState={updateState} />}
			</>
		);
	};

	if (!openVirtualTry) {
		return null;
	}

	return (
		<Dialog
			open
			sx={getDialogWrapperStyles(theme, isSmallScreen, formType)}
			fullWidth
			maxWidth="lg"
			data-test-id={`${formType}_dialog`}
		>
			<ContentWrapper />
		</Dialog>
	);
}
