import { DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ImageAsset } from '@/types/generated';
import HalfScreen from '@/components/HalfScreen';
import TAGButton from '@/components/TAGButton';
import { useAppContext, useFacilityContext } from '@/context';
import { metaData, sys } from '@/constants';

import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
	imageAndQRCodeScanTipsAndUserFormContainerStyles,
} from '../DentrinoVirtualTryOn.styles';
import { IDentrinoTryOnStateObject, resultsPageTitle, resultsSubTitle } from '../DentrinoVirtualTryOn.helpers';

export interface IResults {
	state: IDentrinoTryOnStateObject;
}

export default function Results({ state }: IResults) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { officeInfo } = useFacilityContext();
	const { config } = useAppContext();

	const { resultsFromDentrino } = state;
	const beforeImageAsset = {
		desktopImage: {
			image: {
				cdn_url: resultsFromDentrino?.simulation.storage.beforeUrl,
				appendWebpToUrl: false,
			},
		},
	} as ImageAsset;

	const afterImageAsset = {
		desktopImage: {
			image: {
				cdn_url: resultsFromDentrino?.simulation.storage.resultUrl,
				appendWebpToUrl: false,
			},
		},
	} as ImageAsset;

	const schedulingPath = config.features.otherApps.schedulingPath;

	let schedulingUrl = global.window.location?.origin + `/${schedulingPath}/`;
	if (officeInfo?.code) {
		schedulingUrl = schedulingUrl + `?fc=${officeInfo.code}`;
	}

	return (
		<DialogContent>
			<Grid container sx={dialogContentContainerStyles(isSmallScreen)}>
				<Grid item container sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<HalfScreen
						imageAsset={beforeImageAsset}
						imageAssetTwo={afterImageAsset}
						contentfulMetadata={metaData}
						sys={sys}
						_id={''}
						forceHideContentHalf={true}
					/>
				</Grid>
				<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<Grid
						container
						sx={imageAndQRCodeScanTipsAndUserFormContainerStyles(
							isSmallScreen,
							theme,
							state.formType,
							false
						)}
					>
						<Grid item textAlign={isSmallScreen ? 'center' : 'left'}>
							<Typography
								variant={isSmallScreen ? 'header1' : 'header2'}
								color={isSmallScreen ? 'text.light' : 'text.primary'}
							>
								{resultsPageTitle}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant={'bodyMediumBook'}
								color={isSmallScreen ? 'text.light' : 'text.primary'}
							>
								{resultsSubTitle}
							</Typography>
						</Grid>
						<Grid item marginTop={!isSmallScreen ? '1.75rem' : ''}>
							<TAGButton
								size="M"
								variant={isSmallScreen ? 'secondaryHC' : 'primaryAccent1'}
								href={schedulingUrl}
							>
								Schedule appointment
							</TAGButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</DialogContent>
	);
}
