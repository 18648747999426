import { DialogContent, Grid, useMediaQuery, useTheme } from '@mui/material';
import QRCode from 'react-qr-code';

import { IDentrinoTryOnStateObject, qrCodeUrl } from '../DentrinoVirtualTryOn.helpers';
import ImageSelectOptions from '../ImageSelectOptions/ImageSelectOptions';
import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
	imageSelectOptionsStyles,
	qrCodeCtrStyles,
} from '../DentrinoVirtualTryOn.styles';
import ImageAndQRCodeScanTips from '../ImageAndQRCodeScanTips/ImageAndQRCodeScanTips';

export interface IQRCodeScan {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function QRCodeScan({ state, setState }: IQRCodeScan) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<DialogContent>
			<Grid container sx={dialogContentContainerStyles(isSmallScreen)}>
				<Grid item container sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<Grid item sx={qrCodeCtrStyles}>
						<QRCode
							size={256}
							value={qrCodeUrl}
							style={{ padding: '1.5rem', background: 'white' }}
							viewBox={`0 0 256 256`}
						/>
					</Grid>
					<Grid item sx={imageSelectOptionsStyles}>
						<ImageSelectOptions state={state} setState={setState} />
					</Grid>
				</Grid>
				<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<ImageAndQRCodeScanTips showQrCodeScanTips formType={state.formType} />
				</Grid>
			</Grid>
		</DialogContent>
	);
}
