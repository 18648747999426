import { Grid, IconButton, List, ListItem, ListItemText, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ImageWrapper from '@/components/ImageWrapper';

import {
	imageHelperExampleTitle,
	imageHelperImageSrc,
	imageHelperSteps,
	imageHelperTitle,
	imageUploadHelpSteps,
	qrCodeHelpSteps,
	qrCodeHelpTitle,
	qrCodeIssueHelpText,
	qrCodeIssueHelpTitle,
} from '../DentrinoVirtualTryOn.helpers';
import {
	getCloseIconStyles,
	helperTipsImageWrapperStyles,
	imageAndQRCodeScanTipsAndUserFormContainerStyles,
	listItemStyles,
	listStyles,
} from '../DentrinoVirtualTryOn.styles';

interface ImageAndQRCodeScanTips {
	displayAsToolTip?: boolean;
	handleClose?: () => void;
	formType?: string;
	showQrCodeScanTips?: boolean;
}

export default function ImageAndQRCodeScanTips({
	displayAsToolTip = false,
	handleClose,
	formType,
	showQrCodeScanTips = false,
}: ImageAndQRCodeScanTips) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const helperTitle = showQrCodeScanTips ? qrCodeHelpTitle : imageHelperTitle;

	let helperSteps: Array<string> = [];
	if (formType === 'ImageFromUpload') {
		helperSteps = imageUploadHelpSteps;
	} else if (formType === 'QRCodeScan') {
		helperSteps = qrCodeHelpSteps;
	} else {
		helperSteps = imageHelperSteps;
	}

	return (
		<>
			<Grid
				item
				container
				sx={imageAndQRCodeScanTipsAndUserFormContainerStyles(
					isSmallScreen,
					theme,
					formType || '',
					displayAsToolTip
				)}
			>
				{displayAsToolTip && (
					<IconButton
						aria-label="close"
						onClick={handleClose}
						sx={getCloseIconStyles(formType || '', isSmallScreen)}
					>
						<CloseIcon />
					</IconButton>
				)}
				{!displayAsToolTip && (
					<Grid item>
						<Typography variant={'header2'} color={'text.primary'}>
							{helperTitle}
						</Typography>
					</Grid>
				)}
				<Grid item>
					<List sx={listStyles}>
						{helperSteps.map((step, index) =>
							step ? (
								<ListItem
									key={index}
									disablePadding
									sx={listItemStyles(
										theme,
										isSmallScreen ? 'bodyMediumBook' : 'bodyMediumBook',
										displayAsToolTip
									)}
								>
									<ListItemText>
										<Typography
											variant={isSmallScreen ? 'bodyMediumBook' : 'bodyMediumBook'}
											color={displayAsToolTip ? 'text.light' : 'text.secondary'}
											component={'p'}
										>
											{step}
										</Typography>
									</ListItemText>
								</ListItem>
							) : null
						)}
					</List>
				</Grid>
				{!showQrCodeScanTips && (
					<Grid item>
						<Typography
							variant={isSmallScreen ? 'bodyMediumBook' : 'header4'}
							color={displayAsToolTip ? 'text.light' : 'text.primary'}
						>
							{imageHelperExampleTitle}
						</Typography>
					</Grid>
				)}
				{showQrCodeScanTips && (
					<Grid item container>
						<Typography variant={'bodySmallBold'}>{qrCodeIssueHelpTitle}</Typography>
						<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyMediumBook'}>
							{qrCodeIssueHelpText}
						</Typography>
					</Grid>
				)}
				{!showQrCodeScanTips && (
					<Grid item>
						<ImageWrapper src={imageHelperImageSrc} sx={helperTipsImageWrapperStyles} />
					</Grid>
				)}
			</Grid>
		</>
	);
}
