import { type Area } from 'react-easy-crop';

import { CroppedImage } from '../DentrinoVirtualTryOn.helpers';

export const createImage = (url: string) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.src = url;
	});

export const getCroppedImg = async (imageSrc: string, pixelCrop: Area) => {
	const image = (await createImage(imageSrc)) as HTMLImageElement;

	if (!image) {
		return;
	}

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const maxSize = Math.max(image.width, image.height);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	canvas.width = safeArea;
	canvas.height = safeArea;

	ctx?.drawImage(image as CanvasImageSource, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
	const data = ctx?.getImageData(0, 0, safeArea, safeArea);

	if (!data) {
		return;
	}

	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx?.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
	);

	return new Promise((resolve, reject) => {
		canvas.toBlob((blob) => {
			if (blob) {
				const blobUrl = URL.createObjectURL(blob);

				resolve({ blob, url: blobUrl, height: canvas.height, width: canvas.width } as CroppedImage);
			} else {
				reject(new Error('DENTRINO_ERROR_CANVAS_EMPTY'));
			}
		});
	});
};
