import { Theme } from '@mui/material';

export const focusHelpBoxStyles = (theme: Theme) => ({
	position: 'absolute',
	top: '15%',
	left: { xs: '5%', md: '25%' },
	height: { xs: '60%', md: '60%' },
	width: { xs: '90%', md: '50%' },
	border: `.5rem dashed ${theme.palette.text.light}`,
	borderRadius: '50%',
	zIndex: 2,
});

export const mobileCameraCtrlsCtrStyles = {
	position: 'fixed',
	bottom: 0,
	display: 'flex',
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
	background: 'rgba(0, 0, 0, 0.60)',
	height: '8.25rem',
	zIndex: 2,
	padding: '0 1.5rem',
};

export const mobileIconWithTextStyles = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
};

export const mobileIconBoxStyle = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '4rem',
	width: '4rem',
	borderRadius: '50%',
	background: 'rgba(167, 173, 176, 0.20)',
};

export const mobileIconOverLayStyles = {
	position: 'fixed',
	top: 0,
	height: '5rem',
	width: '100%',
	background: 'rgba(0, 0, 0, 0.60)',
	zIndex: 2,
};
