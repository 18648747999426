import { CroppedImage } from '../DentrinoVirtualTryOn.helpers';

export const getUserInfoImageStyles = (croppedImage: CroppedImage) => ({
	width: 'max-content',
	maxWidth: '100%',
	height: { xs: '100%', md: `${croppedImage.height}` },
	maxHeight: { xs: '14.5rem', md: '35rem' },
	alignItems: 'center',
	filter: 'blur(.5rem)',
});

export const getOverLayStyles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'rgba(0,0,0, 0.7)',
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	zIndex: 4,
	width: '100%',
	height: '100%',
	textAlign: 'center',
	textColor: 'white',
	color: 'white',
};

export const getSpinnerStyles = {
	'&.MuiCircularProgress-root': {
		color: 'white',
	},
};

export const getLegalTextCtrStyles = {
	'&.MuiGrid-item': {
		paddingLeft: '3.125rem',
	},
};

export const getCheckboxStyles = (isSmallScreen: boolean) => ({
	'.MuiFormControlLabel-root > span.PrivateSwitchBase-root': {
		marginTop: isSmallScreen ? '-1.1rem' : '-2.5rem',
	},
});
