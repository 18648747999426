import { logError } from '@/utils/miscUtils';
import { httpClient } from '@/utils';
import { UserEventsAPIFormData } from '@/types';

export default async function postDentrinoForm(url: string, formData: UserEventsAPIFormData) {
	try {
		const response = await httpClient.request({
			url: url,
			requestName: 'postDentrinoFormData',
			method: 'POST',
			data: formData,
		});
		return response;
	} catch (err) {
		if (err instanceof Error) {
			logError(`DENTRINO_ERROR_USER_FORM_SUBMISSION: ${err.message}`);
		}
	}
}
