export const uploadContentDropGridItemStyle = {
	display: 'flex',
	justifyContent: 'center',
	marginTop: '20%',
	flex: 1,
};

export const uploadContentBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	gap: '.75rem',
	justifyContent: 'center',
	alignItems: 'center',
	height: '13rem',
	width: '29rem',
	border: '1px solid',
	borderRadius: '.5rem',
	backgroundColor: 'secondary.light',
};
