import { useEffect, useRef } from 'react';

import { Box, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import { logError } from '@/utils/miscUtils';
import TAGSvgIcon from '@/components/TAGSvgIcon';

import { IDentrinoTryOnStateObject, cameraAccessErrorMessage } from '../DentrinoVirtualTryOn.helpers';
import ImageSelectOptions from '../ImageSelectOptions/ImageSelectOptions';
import ImageAndQRCodeScanTips from '../ImageAndQRCodeScanTips/ImageAndQRCodeScanTips';
import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
	imageSelectOptionsStyles,
} from '../DentrinoVirtualTryOn.styles';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

import {
	focusHelpBoxStyles,
	mobileCameraCtrlsCtrStyles,
	mobileIconBoxStyle,
	mobileIconOverLayStyles,
	mobileIconWithTextStyles,
} from './ImageFromCamera.styles';

export interface IImageFromCamera {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function ImageFromCamera({ state, setState }: IImageFromCamera) {
	const imageFileUploadCamRef = useRef<HTMLInputElement>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const { showHelperTipsInMobile = false, cameraAccessError } = state;

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const openCamera = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia(constraints);
			if (videoRef?.current) {
				videoRef.current.srcObject = stream;
				await videoRef.current.play();
			}
		} catch (err) {
			if (err instanceof Error) {
				logError(`DENTRINO_ERROR_OPEN_CAMERA: ${err.message}`);
				if (isSmallScreen) {
					setState({
						formType: 'Error',
						errorMessage: cameraAccessErrorMessage,
						cameraAccessError: true,
					});
				} else {
					//display error in the same page for desktop
					setState({
						errorMessage: cameraAccessErrorMessage,
						cameraAccessError: true,
					});
				}
				return;
			}
		}
	};

	useEffect(() => {
		if (!cameraAccessError) {
			void openCamera();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cameraAccessError]);

	const constraints = {
		video: {
			facingMode: { ideal: 'user' },
		},
		audio: false,
	};

	const closeCamera = () => {
		try {
			if (videoRef?.current) {
				const stream = videoRef.current.srcObject as MediaStream;
				const tracks = stream.getTracks();
				tracks.forEach((track) => {
					track.stop();
				});
				videoRef.current.srcObject = null;
			}
		} catch (err) {
			if (err instanceof Error) {
				logError(`DENTRINO_ERROR_CLOSE_CAMERA: ${err.message}`);
			}
		}
	};

	const captureImage = () => {
		if (videoRef?.current && canvasRef?.current) {
			const video = videoRef.current;
			const canvas = canvasRef.current;
			const context = canvas.getContext('2d');
			if (context && video.videoWidth && video.videoHeight) {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;

				// Draw video frame onto canvas
				context.drawImage(video, 0, 0, canvas.width, canvas.height);

				// Get image data URL from canvas
				const imageDataUrl = canvas.toDataURL('image/jpeg');

				closeCamera();
				// Set the captured image
				setState({
					selectedFileUrl: imageDataUrl,
					formType: 'ImageCrop',
					previousStep: 'ImageFromCamera',
				});
			}
		}
	};

	const handleUpload = () => {
		if (imageFileUploadCamRef.current) {
			imageFileUploadCamRef.current.click();
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const selectedFiles = event.target.files;
		if (selectedFiles && selectedFiles.length > 0) {
			const file = Array.from(selectedFiles)[0];
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				function () {
					closeCamera();
					setState({
						selectedFileUrl: reader.result as string,
						formType: 'ImageCrop',
						previousStep: 'ImageFromCamera',
					});
				},
				false
			);
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	const openHelperTips = () => {
		setState({
			...state,
			showHelperTipsInMobile: true,
		});
	};

	const closeHelperTips = () => {
		setState({
			...state,
			showHelperTipsInMobile: false,
		});
	};

	return (
		<DialogContent>
			<Grid container sx={dialogContentContainerStyles(isSmallScreen)}>
				{isSmallScreen && <Grid item sx={mobileIconOverLayStyles} />}
				<Grid item container sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}>
					{!state.cameraAccessError && (
						<>
							<Grid item sx={focusHelpBoxStyles(theme)} />
							<Grid item height={'100%'} width={'100%'}>
								<video
									ref={videoRef}
									style={{
										objectFit: 'cover',
										width: '100%',
										height: '100%',
										position: 'absolute',
										top: 0,
										left: 0,
										zIndex: 0,
										transform: 'scale(-1,1)',
									}}
									disablePictureInPicture
									playsInline
									controls={false}
								>
									<track kind="captions" srcLang="en" label="english_captions"></track>
								</video>
								<canvas style={{ display: 'none' }} ref={canvasRef} />
							</Grid>
						</>
					)}
					{state.cameraAccessError && (
						<Grid item height={'100%'} width={'100%'}>
							<ErrorMessage state={state} setState={setState}></ErrorMessage>
						</Grid>
					)}
					{!isSmallScreen && (
						<Grid item sx={imageSelectOptionsStyles}>
							<ImageSelectOptions state={state} setState={setState} handleCameraClick={captureImage} />
						</Grid>
					)}
				</Grid>
				{!isSmallScreen && (
					<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
						<ImageAndQRCodeScanTips formType={state.formType} />
					</Grid>
				)}
				{isSmallScreen && (
					<Grid container sx={mobileCameraCtrlsCtrStyles}>
						<Grid item sx={mobileIconWithTextStyles} onClick={handleUpload}>
							<Box sx={mobileIconBoxStyle}>
								<TAGSvgIcon
									icon={'FileUploadOutlined'}
									size={32}
									viewBox="0 0 32 32"
									style={{ color: `${theme.palette.text.disabled}` }}
								/>
								<input
									type="file"
									ref={imageFileUploadCamRef}
									hidden
									id="browse"
									onChange={handleFileChange}
									accept=".png,.jpeg,.jpg"
								/>
							</Box>
							<Typography variant={'bodyMediumBook'} color={'text.light'}>
								Upload
							</Typography>
						</Grid>
						<Grid item onClick={captureImage}>
							<TAGSvgIcon
								icon={'CircleTwoTone'}
								size={60}
								viewBox="0 0 60 60"
								style={{ color: `${theme.palette.text.light}` }}
							/>
						</Grid>
						<Grid item sx={mobileIconWithTextStyles} onClick={openHelperTips}>
							<Box sx={mobileIconBoxStyle}>
								<TAGSvgIcon
									icon={'Info'}
									size={32}
									viewBox="0 0 32 32"
									style={{ color: `${theme.palette.text.light}` }}
								/>
							</Box>
							<Typography variant={'bodyMediumBook'} color={'text.light'}>
								Tips
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			{showHelperTipsInMobile && (
				<ImageAndQRCodeScanTips displayAsToolTip handleClose={closeHelperTips} formType={state.formType} />
			)}
		</DialogContent>
	);
}
