import { useRef } from 'react';

import { DialogActions, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

import TAGButton from '@/components/TAGButton';
import ImageWrapper from '@/components/ImageWrapper';

import {
	IDentrinoTryOnStateObject,
	instructionsAndHelperImageSrc,
	instructionsSubTitle,
	instructionsSubTitle2,
	instructionsTitle,
} from '../DentrinoVirtualTryOn.helpers';
import {
	helperTipsImageWrapperStyles,
	instructionTitleStyles,
	instructionsCtrStyles,
	instructionsImageAndStepsCtrStyles,
} from '../DentrinoVirtualTryOn.styles';
export interface IInstructions {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export default function Instructions({ setState }: IInstructions) {
	const imageFileUploadMobileRef = useRef<HTMLInputElement>(null);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const handleUpload = () => {
		if (!isSmallScreen) {
			setState({
				formType: 'ImageFromUpload',
			});
		} else {
			if (imageFileUploadMobileRef.current) {
				imageFileUploadMobileRef.current.click();
			}
		}
	};

	const handleCamera = () => {
		setState({
			formType: 'ImageFromCamera',
		});
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		const selectedFiles = event.target.files;
		if (selectedFiles && selectedFiles.length > 0) {
			const file = Array.from(selectedFiles)[0];
			const reader = new FileReader();
			reader.addEventListener(
				'load',
				function () {
					setState({
						selectedFileUrl: reader.result as string,
						formType: 'ImageCrop',
					});
				},
				false
			);
			if (file) {
				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<>
			<DialogContent>
				<Grid container sx={instructionsCtrStyles(isSmallScreen)}>
					<Grid item sx={instructionTitleStyles}>
						<Typography variant={'header2'} color={'text.light'}>
							{instructionsTitle}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'} color={'text.light'}>
							{instructionsSubTitle}
						</Typography>
					</Grid>
					<Grid item container sx={instructionsImageAndStepsCtrStyles(isSmallScreen)}>
						<Grid item>
							<ImageWrapper src={instructionsAndHelperImageSrc} sx={helperTipsImageWrapperStyles} />
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant={isSmallScreen ? 'bodyMediumBook' : 'bodyLargeBook'} color={'text.light'}>
							{instructionsSubTitle2}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Grid container spacing={2} justifyContent="center" alignItems="center">
					<Grid item>
						<TAGButton size={isSmallScreen ? 'S' : 'M'} variant="secondaryHC" onClick={handleUpload}>
							Upload image
						</TAGButton>
						{isSmallScreen && (
							<input
								type="file"
								ref={imageFileUploadMobileRef}
								hidden
								id="browse"
								onChange={handleFileChange}
								accept=".png,.jpeg,.jpg"
							/>
						)}
					</Grid>
					<Grid item>
						<TAGButton size={isSmallScreen ? 'S' : 'M'} variant="primaryHC" onClick={handleCamera}>
							Open camera
						</TAGButton>
					</Grid>
				</Grid>
			</DialogActions>
		</>
	);
}
