import { Box, CircularProgress, DialogContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Form, SingleCheckbox, TextInput, useHookForm } from '@aspendental/shared-forms-web';
import { FieldValues } from 'react-hook-form';
import { getSessionId } from '@aspendental/shared-utils/utils';

import ImageWrapper from '@/components/ImageWrapper';
import TAGButton from '@/components/TAGButton';
import { useAppContext } from '@/context';
import postDentrinoForm from '@/services/postDentrinoForm';

import {
	IDentrinoTryOnStateObject,
	TUserInfo,
	apiErrorMessageToDisplay,
	blurredResultImageText,
	defaultValues,
	dentrinoGA4DataLayerPush,
	getResultsFromDentrino,
	legalNoticeText,
	legalNoticeText2,
	schema,
	userForm,
} from '../DentrinoVirtualTryOn.helpers';
import {
	dialogContentContainerStyles,
	dialogLeftContentCtrStyles,
	dialogRightContentCtrStyles,
	imageAndQRCodeScanTipsAndUserFormContainerStyles,
} from '../DentrinoVirtualTryOn.styles';

import {
	getCheckboxStyles,
	getLegalTextCtrStyles,
	getOverLayStyles,
	getSpinnerStyles,
	getUserInfoImageStyles,
} from './UserInfo.styles';

export interface IUserInfo {
	state: IDentrinoTryOnStateObject;
	setState: (newState: Partial<IDentrinoTryOnStateObject>) => void;
}

export type TAnswer = string | boolean | null;

export default function UserInfo({ state, setState }: IUserInfo) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { environment, config, dentrinoClientId, dentrinoClientSecret } = useAppContext();

	const { croppedImage, isLoading = false } = state;

	const form = useHookForm({
		schema,
		defaultValues,
		mode: 'onBlur',
	});

	if (!croppedImage.blob) {
		return null;
	}

	const dentrinoApiurl =
		environment === 'prod' ? config.services.prod.DENTRINO_API_URL : config.services.nonprod.DENTRINO_API_URL;
	const formsApiurl =
		environment === 'prod' ? config.services.prod.FORM_EVENTS_URL : config.services.nonprod.FORM_EVENTS_URL;

	const disableSubmitButton = !form.formState.isValid || form.formState.isSubmitting || form.formState.isSubmitted;

	const submitUserDetails = async (fields: FieldValues) => {
		const userFormDetailsData = userForm.fields.map((userField: TUserInfo) => {
			const answerValue = fields[userField.name] as TAnswer;
			return {
				question: userField.label,
				answer: answerValue?.toString() || '',
				unique_label: userField.unique_label,
			};
		});

		await postDentrinoForm(formsApiurl, {
			form: {
				brand: config.name,
				form_title: 'Smile Try-On',
				product_line: 'Tooth Replacement',
				form_description: 'Dentrino Virtual Try-On',
				form_type: 'Interactive Form',
				form_channel: 'Web',
				session_id: getSessionId() || 'storybook',
				form_questions: userFormDetailsData,
			},
		});

		dentrinoGA4DataLayerPush(config.name, 'submit');
	};

	const handleSubmit = async (fields: FieldValues) => {
		setState({
			...state,
			isLoading: true,
		});
		const resultsFromDentrino = await getResultsFromDentrino(
			croppedImage,
			dentrinoApiurl,
			dentrinoClientId,
			dentrinoClientSecret
		);
		if (resultsFromDentrino?.success) {
			void submitUserDetails(fields);
			setState({
				formType: 'Results',
				resultsFromDentrino,
			});
		} else {
			setState({
				isLoading: false,
				formType: 'Error',
				errorMessage: apiErrorMessageToDisplay,
				cameraAccessError: false,
			});
		}
	};

	return (
		<DialogContent>
			<Grid container sx={dialogContentContainerStyles(isSmallScreen)}>
				<Grid item container sx={dialogLeftContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<ImageWrapper src={croppedImage.url} sx={getUserInfoImageStyles(croppedImage)} />
					<Box sx={getOverLayStyles}>
						<Typography variant="header2" color={'text.light'}>
							{blurredResultImageText}
						</Typography>
					</Box>
				</Grid>
				<Grid item container sx={dialogRightContentCtrStyles(theme, state.formType, isSmallScreen)}>
					<Grid
						container
						sx={imageAndQRCodeScanTipsAndUserFormContainerStyles(
							isSmallScreen,
							theme,
							state.formType,
							false
						)}
					>
						<Grid item>
							<Typography variant={'header2'} color={'text.primary'}>
								Submit your details
							</Typography>
						</Grid>
						<Grid item width="100%">
							<Form form={form} onSubmit={handleSubmit} data-test-id="user_details_form">
								<Grid
									container
									direction="column"
									spacing={2}
									justifyContent="center"
									alignItems="center"
									marginBottom="2.5rem"
								>
									{userForm.fields.map((field: TUserInfo) => {
										if (field.type === 'Text') {
											return (
												<Grid item width="100%" key={field.name}>
													<TextInput
														name={field.name}
														label={field.label}
														enforceAlphaNumeric={false}
														required={field.required}
													/>
												</Grid>
											);
										} else if (field.type === 'Checkbox') {
											return (
												<Grid
													item
													width="100%"
													key={field.name}
													sx={getCheckboxStyles(isSmallScreen)}
												>
													<SingleCheckbox name={field.name} label={field.label} />
												</Grid>
											);
										}
									})}
									<Grid item sx={getLegalTextCtrStyles}>
										<Typography
											sx={{ display: 'block' }}
											variant={'bodyMediumBold'}
											color={'text.secondary'}
										>
											What you need to know
										</Typography>
										<Typography variant={'bodyMediumBook'} color={'text.secondary'}>
											{legalNoticeText}
										</Typography>
									</Grid>
									<Grid item sx={getLegalTextCtrStyles}>
										<Typography variant={'bodyMediumBook'} color={'text.secondary'}>
											{legalNoticeText2}
										</Typography>
									</Grid>
									<Grid item>
										<TAGButton
											size="M"
											variant="primaryDefault"
											type="submit"
											disabled={disableSubmitButton}
										>
											Submit
										</TAGButton>
									</Grid>
								</Grid>
							</Form>
						</Grid>
					</Grid>
				</Grid>
				{isLoading && (
					<Grid item container sx={getOverLayStyles}>
						<CircularProgress size={80} thickness={3.5} sx={getSpinnerStyles} />
					</Grid>
				)}
			</Grid>
		</DialogContent>
	);
}
